var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('console-content', {
    attrs: {
      "max-width": "1024"
    }
  }, [_c('v-row', {
    staticClass: "mt-3 mt-md-8 px-1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "headline",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("테마 등록")]), _c('v-spacer')], 1), _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0 pr-md-1",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    staticClass: "mx-1"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("썸네일")]), _c('v-card-text', [_c('div', {
    staticClass: "pa-1"
  }, [_c('v-sheet', {
    staticClass: "v-responsive d-flex align-center justify-center",
    attrs: {
      "outlined": "",
      "min-height": "256"
    }
  }, [_vm.theme.images[_vm.previewIndex] ? _c('v-img', {
    attrs: {
      "src": _vm.images[_vm.previewIndex],
      "width": "100%",
      "contain": ""
    }
  }) : _c('v-icon', [_vm._v("mdi-image")])], 1)], 1), _c('draggable', {
    staticClass: "d-flex v-row",
    model: {
      value: _vm.theme.images,
      callback: function ($$v) {
        _vm.$set(_vm.theme, "images", $$v);
      },
      expression: "theme.images"
    }
  }, _vm._l(_vm.theme.images, function (image, index) {
    return _c('v-col', {
      key: index,
      staticClass: "pa-1 v-responsive"
    }, [_c('v-responsive', [_c('v-responsive', {
      attrs: {
        "aspect-ratio": 1 / 1
      },
      on: {
        "click": function ($event) {
          _vm.previewIndex = index;
        }
      }
    }, [_c('v-sheet', {
      staticClass: "d-flex align-center justify-center",
      attrs: {
        "outlined": "",
        "width": "100%",
        "height": "100%"
      }
    }, [image ? _c('v-img', {
      attrs: {
        "src": _vm.images[index],
        "max-width": "100%",
        "max-height": "100%",
        "contain": ""
      }
    }) : _c('v-icon', {
      attrs: {
        "color": "grey"
      }
    }, [_vm._v("mdi-image")])], 1)], 1), _c('v-file-input', {
      attrs: {
        "dense": "",
        "hide-details": "",
        "placeholder": "upload"
      },
      model: {
        value: _vm.theme.images[index],
        callback: function ($$v) {
          _vm.$set(_vm.theme.images, index, $$v);
        },
        expression: "theme.images[index]"
      }
    })], 1)], 1);
  }), 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "py-0 pl-md-1",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    staticClass: "mx-1 mt-3 mt-md-0"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("기본정보")]), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "테마 명칭",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.name,
      callback: function ($$v) {
        _vm.$set(_vm.theme, "name", $$v);
      },
      expression: "theme.name"
    }
  }), _c('v-textarea', {
    staticClass: "mt-4",
    attrs: {
      "label": "테마 설명",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.description,
      callback: function ($$v) {
        _vm.$set(_vm.theme, "description", $$v);
      },
      expression: "theme.description"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4 mx-1"
  }, [_c('v-card-title', [_c('span', {
    staticClass: "subtitle-2"
  }, [_c('b', [_vm._v("Color")])]), _c('v-spacer')], 1), _c('v-divider'), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('div', {
    staticClass: "d-flex"
  }, [_c('b', {
    staticClass: "subtitle-2"
  }, [_vm._v("Primary")]), _c('v-spacer'), _c('v-btn', {
    staticClass: "px-1",
    attrs: {
      "text": "",
      "color": "primary",
      "min-width": "20"
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "20"
    }
  }, [_vm._v(_vm._s(_vm.mdiPalette))])], 1)], 1), _c('v-text-field', {
    attrs: {
      "label": "base",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.primary.base,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.primary, "base", $$v);
      },
      expression: "theme.themes.light.primary.base"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "lighten1",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.primary.lighten1,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.primary, "lighten1", $$v);
      },
      expression: "theme.themes.light.primary.lighten1"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "lighten2",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.primary.lighten2,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.primary, "lighten2", $$v);
      },
      expression: "theme.themes.light.primary.lighten2"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "lighten3",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.primary.lighten3,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.primary, "lighten3", $$v);
      },
      expression: "theme.themes.light.primary.lighten3"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "lighten4",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.primary.lighten4,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.primary, "lighten4", $$v);
      },
      expression: "theme.themes.light.primary.lighten4"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "lighten5",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.primary.lighten5,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.primary, "lighten5", $$v);
      },
      expression: "theme.themes.light.primary.lighten5"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "darken1",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.primary.darken1,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.primary, "darken1", $$v);
      },
      expression: "theme.themes.light.primary.darken1"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "darken2",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.primary.darken2,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.primary, "darken2", $$v);
      },
      expression: "theme.themes.light.primary.darken2"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "darken3",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.primary.darken3,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.primary, "darken3", $$v);
      },
      expression: "theme.themes.light.primary.darken3"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "darken4",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.primary.darken4,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.primary, "darken4", $$v);
      },
      expression: "theme.themes.light.primary.darken4"
    }
  })], 1), _c('v-col', [_c('div', {
    staticClass: "d-flex"
  }, [_c('b', {
    staticClass: "subtitle-2"
  }, [_vm._v("Secondary")]), _c('v-spacer'), _c('v-btn', {
    staticClass: "px-1",
    attrs: {
      "text": "",
      "color": "primary",
      "min-width": "20"
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "20"
    }
  }, [_vm._v(_vm._s(_vm.mdiPalette))])], 1)], 1), _c('v-text-field', {
    attrs: {
      "label": "base",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.secondary.base,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.secondary, "base", $$v);
      },
      expression: "theme.themes.light.secondary.base"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "lighten1",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.secondary.lighten1,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.secondary, "lighten1", $$v);
      },
      expression: "theme.themes.light.secondary.lighten1"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "lighten2",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.secondary.lighten2,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.secondary, "lighten2", $$v);
      },
      expression: "theme.themes.light.secondary.lighten2"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "lighten3",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.secondary.lighten3,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.secondary, "lighten3", $$v);
      },
      expression: "theme.themes.light.secondary.lighten3"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "lighten4",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.secondary.lighten4,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.secondary, "lighten4", $$v);
      },
      expression: "theme.themes.light.secondary.lighten4"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "lighten5",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.secondary.lighten5,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.secondary, "lighten5", $$v);
      },
      expression: "theme.themes.light.secondary.lighten5"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "darken1",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.secondary.darken1,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.secondary, "darken1", $$v);
      },
      expression: "theme.themes.light.secondary.darken1"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "darken2",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.secondary.darken2,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.secondary, "darken2", $$v);
      },
      expression: "theme.themes.light.secondary.darken2"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "darken3",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.secondary.darken3,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.secondary, "darken3", $$v);
      },
      expression: "theme.themes.light.secondary.darken3"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "darken4",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.secondary.darken4,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.secondary, "darken4", $$v);
      },
      expression: "theme.themes.light.secondary.darken4"
    }
  })], 1), _c('v-col', [_c('div', {
    staticClass: "d-flex"
  }, [_c('b', {
    staticClass: "subtitle-2"
  }, [_vm._v("Accent")]), _c('v-spacer'), _c('v-btn', {
    staticClass: "px-1",
    attrs: {
      "text": "",
      "color": "primary",
      "min-width": "20"
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "20"
    }
  }, [_vm._v(_vm._s(_vm.mdiPalette))])], 1)], 1), _c('v-text-field', {
    attrs: {
      "label": "base",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.accent.base,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.accent, "base", $$v);
      },
      expression: "theme.themes.light.accent.base"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "lighten1",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.accent.lighten1,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.accent, "lighten1", $$v);
      },
      expression: "theme.themes.light.accent.lighten1"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "lighten2",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.accent.lighten2,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.accent, "lighten2", $$v);
      },
      expression: "theme.themes.light.accent.lighten2"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "lighten3",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.accent.lighten3,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.accent, "lighten3", $$v);
      },
      expression: "theme.themes.light.accent.lighten3"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "lighten4",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.accent.lighten4,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.accent, "lighten4", $$v);
      },
      expression: "theme.themes.light.accent.lighten4"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "lighten5",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.accent.lighten5,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.accent, "lighten5", $$v);
      },
      expression: "theme.themes.light.accent.lighten5"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "darken1",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.accent.darken1,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.accent, "darken1", $$v);
      },
      expression: "theme.themes.light.accent.darken1"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "darken2",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.accent.darken2,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.accent, "darken2", $$v);
      },
      expression: "theme.themes.light.accent.darken2"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "darken3",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.accent.darken3,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.accent, "darken3", $$v);
      },
      expression: "theme.themes.light.accent.darken3"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "darken4",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.accent.darken4,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.accent, "darken4", $$v);
      },
      expression: "theme.themes.light.accent.darken4"
    }
  })], 1), _c('v-col', [_c('div', {
    staticClass: "d-flex"
  }, [_c('b', {
    staticClass: "subtitle-2"
  }, [_vm._v("Content")]), _c('v-spacer'), _c('v-btn', {
    staticClass: "px-1",
    attrs: {
      "text": "",
      "color": "primary",
      "min-width": "20"
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "20"
    }
  }, [_vm._v(_vm._s(_vm.mdiPalette))])], 1)], 1), _c('v-text-field', {
    attrs: {
      "label": "base",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.content.base,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.content, "base", $$v);
      },
      expression: "theme.themes.light.content.base"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "lighten1",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.content.lighten1,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.content, "lighten1", $$v);
      },
      expression: "theme.themes.light.content.lighten1"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "lighten2",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.content.lighten2,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.content, "lighten2", $$v);
      },
      expression: "theme.themes.light.content.lighten2"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "lighten3",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.content.lighten3,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.content, "lighten3", $$v);
      },
      expression: "theme.themes.light.content.lighten3"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "lighten4",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.content.lighten4,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.content, "lighten4", $$v);
      },
      expression: "theme.themes.light.content.lighten4"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "lighten5",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.content.lighten5,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.content, "lighten5", $$v);
      },
      expression: "theme.themes.light.content.lighten5"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "darken1",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.content.darken1,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.content, "darken1", $$v);
      },
      expression: "theme.themes.light.content.darken1"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "darken2",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.content.darken2,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.content, "darken2", $$v);
      },
      expression: "theme.themes.light.content.darken2"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "darken3",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.content.darken3,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.content, "darken3", $$v);
      },
      expression: "theme.themes.light.content.darken3"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "darken4",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.theme.themes.light.content.darken4,
      callback: function ($$v) {
        _vm.$set(_vm.theme.themes.light.content, "darken4", $$v);
      },
      expression: "theme.themes.light.content.darken4"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4 mx-1"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("Page Templates")]), _c('v-card-text', [_c('v-autocomplete', {
    attrs: {
      "label": "기본(전체)",
      "persistent-placeholder": "",
      "items": _vm.$templates,
      "prefix": "@/templates/"
    },
    model: {
      value: _vm.theme.pages.default,
      callback: function ($$v) {
        _vm.$set(_vm.theme.pages, "default", $$v);
      },
      expression: "theme.pages.default"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "label": "메인화면",
      "persistent-placeholder": "",
      "items": _vm.$templates,
      "prefix": "@/templates/"
    },
    model: {
      value: _vm.theme.pages.main,
      callback: function ($$v) {
        _vm.$set(_vm.theme.pages, "main", $$v);
      },
      expression: "theme.pages.main"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "label": "로그인",
      "persistent-placeholder": "",
      "items": _vm.$templates,
      "prefix": "@/templates/"
    },
    model: {
      value: _vm.theme.pages.login,
      callback: function ($$v) {
        _vm.$set(_vm.theme.pages, "login", $$v);
      },
      expression: "theme.pages.login"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "label": "어바웃페이지",
      "persistent-placeholder": "",
      "items": _vm.$templates,
      "prefix": "@/templates/"
    },
    model: {
      value: _vm.theme.pages.about,
      callback: function ($$v) {
        _vm.$set(_vm.theme.pages, "about", $$v);
      },
      expression: "theme.pages.about"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "label": "대량문의페이지",
      "persistent-placeholder": "",
      "items": _vm.$templates,
      "prefix": "@/templates/"
    },
    model: {
      value: _vm.theme.pages.contact,
      callback: function ($$v) {
        _vm.$set(_vm.theme.pages, "contact", $$v);
      },
      expression: "theme.pages.contact"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "label": "마이페이지",
      "persistent-placeholder": "",
      "items": _vm.$templates,
      "prefix": "@/templates/"
    },
    model: {
      value: _vm.theme.pages.mypage,
      callback: function ($$v) {
        _vm.$set(_vm.theme.pages, "mypage", $$v);
      },
      expression: "theme.pages.mypage"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "label": "고객센터",
      "persistent-placeholder": "",
      "items": _vm.$templates,
      "prefix": "@/templates/"
    },
    model: {
      value: _vm.theme.pages.center,
      callback: function ($$v) {
        _vm.$set(_vm.theme.pages, "center", $$v);
      },
      expression: "theme.pages.center"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "label": "상품권몰",
      "persistent-placeholder": "",
      "items": _vm.$templates,
      "prefix": "@/templates/"
    },
    model: {
      value: _vm.theme.pages.gshop,
      callback: function ($$v) {
        _vm.$set(_vm.theme.pages, "gshop", $$v);
      },
      expression: "theme.pages.gshop"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "label": "쇼핑몰",
      "persistent-placeholder": "",
      "items": _vm.$templates,
      "prefix": "@/templates/"
    },
    model: {
      value: _vm.theme.pages.shop,
      callback: function ($$v) {
        _vm.$set(_vm.theme.pages, "shop", $$v);
      },
      expression: "theme.pages.shop"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "my-6"
  }, [_c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1), _c('v-spacer')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }