<template>
    <console-content max-width="1024">

        <v-row class="mt-3 mt-md-8 px-1" align="center">
            <v-col cols="auto" class="headline">테마 등록</v-col>
            <v-spacer/>
        </v-row>


        <v-row class="mt-3">
            <v-col cols="12" md="6" class="py-0 pr-md-1">
                <v-card class="mx-1">
                    <v-card-title class="subtitle-2 font-weight-bold">썸네일</v-card-title>
                    <v-card-text>
                        <div class="pa-1">
                            <v-sheet outlined min-height="256" class="v-responsive d-flex align-center justify-center">
                                <v-img v-if="theme.images[previewIndex]" :src="images[previewIndex]" width="100%" contain/>
                                <v-icon v-else>mdi-image</v-icon>
                            </v-sheet>
                        </div>
                        <draggable v-model="theme.images" class="d-flex v-row">
                            <v-col v-for="(image, index) in theme.images" :key="index" class="pa-1 v-responsive">
                                <v-responsive>
                                    <v-responsive :aspect-ratio="1/1" @click="previewIndex = index">
                                        <v-sheet outlined class="d-flex align-center justify-center" width="100%" height="100%">
                                            <v-img v-if="image" :src="images[index]" max-width="100%" max-height="100%" contain/>
                                            <v-icon v-else color="grey">mdi-image</v-icon>
                                        </v-sheet>
                                    </v-responsive>
                                    <v-file-input v-model="theme.images[index]" dense hide-details placeholder="upload" />
                                </v-responsive>
                            </v-col>
                        </draggable>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" md="6" class="py-0 pl-md-1">
                <v-card class="mx-1 mt-3 mt-md-0">
                    <v-card-title class="subtitle-2 font-weight-bold">기본정보</v-card-title>
                    <v-card-text>
                        <v-text-field v-model="theme.name" label="테마 명칭" persistent-placeholder hide-details />
                        <v-textarea v-model="theme.description" label="테마 설명" persistent-placeholder hide-details class="mt-4"/>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-card class="mt-4 mx-1">
            <v-card-title>
                <span class="subtitle-2"><b>Color</b></span>
                <v-spacer />
            </v-card-title>
            <v-divider/>
            <v-card-text>
                <v-row>
                    <v-col>
                        <div class="d-flex">
                            <b class="subtitle-2">Primary</b>
                            <v-spacer/>
                            <v-btn text color="primary" class="px-1" min-width="20"><v-icon size="20">{{ mdiPalette }}</v-icon></v-btn>
                        </div>
                        <v-text-field v-model="theme.themes.light.primary.base" label="base" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.primary.lighten1" label="lighten1" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.primary.lighten2" label="lighten2" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.primary.lighten3" label="lighten3" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.primary.lighten4" label="lighten4" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.primary.lighten5" label="lighten5" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.primary.darken1" label="darken1" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.primary.darken2" label="darken2" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.primary.darken3" label="darken3" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.primary.darken4" label="darken4" persistent-placeholder hide-details />
                    </v-col>
                    <v-col>
                        <div class="d-flex">
                            <b class="subtitle-2">Secondary</b>
                            <v-spacer/>
                            <v-btn text color="primary" class="px-1" min-width="20"><v-icon size="20">{{ mdiPalette }}</v-icon></v-btn>
                        </div>
                        <v-text-field v-model="theme.themes.light.secondary.base" label="base" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.secondary.lighten1" label="lighten1" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.secondary.lighten2" label="lighten2" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.secondary.lighten3" label="lighten3" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.secondary.lighten4" label="lighten4" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.secondary.lighten5" label="lighten5" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.secondary.darken1" label="darken1" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.secondary.darken2" label="darken2" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.secondary.darken3" label="darken3" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.secondary.darken4" label="darken4" persistent-placeholder hide-details />
                    </v-col>
                    <v-col>
                        <div class="d-flex">
                            <b class="subtitle-2">Accent</b>
                            <v-spacer/>
                            <v-btn text color="primary" class="px-1" min-width="20"><v-icon size="20">{{ mdiPalette }}</v-icon></v-btn>
                        </div>
                        <v-text-field v-model="theme.themes.light.accent.base" label="base" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.accent.lighten1" label="lighten1" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.accent.lighten2" label="lighten2" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.accent.lighten3" label="lighten3" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.accent.lighten4" label="lighten4" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.accent.lighten5" label="lighten5" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.accent.darken1" label="darken1" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.accent.darken2" label="darken2" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.accent.darken3" label="darken3" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.accent.darken4" label="darken4" persistent-placeholder hide-details />
                    </v-col>
                    <v-col>
                        <div class="d-flex">
                            <b class="subtitle-2">Content</b>
                            <v-spacer/>
                            <v-btn text color="primary" class="px-1" min-width="20"><v-icon size="20">{{ mdiPalette }}</v-icon></v-btn>
                        </div>
                        <v-text-field v-model="theme.themes.light.content.base" label="base" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.content.lighten1" label="lighten1" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.content.lighten2" label="lighten2" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.content.lighten3" label="lighten3" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.content.lighten4" label="lighten4" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.content.lighten5" label="lighten5" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.content.darken1" label="darken1" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.content.darken2" label="darken2" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.content.darken3" label="darken3" persistent-placeholder hide-details />
                        <v-text-field v-model="theme.themes.light.content.darken4" label="darken4" persistent-placeholder hide-details />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card class="mt-4 mx-1">
            <v-card-title class="subtitle-2 font-weight-bold">Page Templates</v-card-title>
            <v-card-text>
                <v-autocomplete v-model="theme.pages.default" label="기본(전체)" persistent-placeholder :items="$templates" prefix="@/templates/" />
                <v-autocomplete v-model="theme.pages.main" label="메인화면" persistent-placeholder :items="$templates" prefix="@/templates/" />
                <v-autocomplete v-model="theme.pages.login" label="로그인" persistent-placeholder :items="$templates" prefix="@/templates/" />
                <v-autocomplete v-model="theme.pages.about" label="어바웃페이지" persistent-placeholder :items="$templates" prefix="@/templates/" />
                <v-autocomplete v-model="theme.pages.contact" label="대량문의페이지" persistent-placeholder :items="$templates" prefix="@/templates/" />
                <v-autocomplete v-model="theme.pages.mypage" label="마이페이지" persistent-placeholder :items="$templates" prefix="@/templates/" />
                <v-autocomplete v-model="theme.pages.center" label="고객센터" persistent-placeholder :items="$templates" prefix="@/templates/" />
                <v-autocomplete v-model="theme.pages.gshop" label="상품권몰" persistent-placeholder :items="$templates" prefix="@/templates/" />
                <v-autocomplete v-model="theme.pages.shop" label="쇼핑몰" persistent-placeholder :items="$templates" prefix="@/templates/" />
            </v-card-text>
        </v-card>

        <v-row class="my-6">
            <v-spacer/>
            <v-col cols="auto" class="py-0">
                <v-btn color="primary" @click="save">저장</v-btn>
            </v-col>
            <v-spacer/>
        </v-row>
    </console-content>
</template>

<script>
import api from "@/api";
import Draggable from "vuedraggable";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import { mdiPalette } from "@mdi/js";
import colors from 'vuetify/lib/util/colors'

export default {
    components: {
        Draggable,
        ConsoleContent,
    },
    data(){
        return {
            colors,
            mdiPalette,

            previewIndex: 0,

            theme: {
                _id: this.$route.params._theme,

                name: null,
                code: null,
                description: null,
                images: new Array(5),

                themes: {
                    light: {
                        primary: {},
                        secondary: {},
                        accent: {},
                        content: {}
                    },
                    dark: {
                        primary: {},
                        secondary: {},
                        accent: {}
                    }
                },

                pages: {
                    default: null,
                    main: null,
                    center: null,
                    login: null,
                    mypage: null,
                    shop: null,
                }
            }
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            if(this.theme._id){
                let { theme } = await api.console.themes.get(this.theme);
                for(let index in theme.images){
                    if(theme.images[index]) theme.images[index] = await api.getResource(theme.images[index]);
                }
                this.theme = theme;
                console.log({ theme: theme });
            }
        },
        async save(){
            let { theme } = this.theme._id ? await api.console.themes.put(this.theme) : await api.console.themes.post(this.theme);
            for(let index in this.theme.images){
                if(this.theme.images[index]) await api.console.themes.images.post(theme._id, index, this.theme.images[index]);
            }

            alert("저장되었습니다");
            this.$router.go(-1);
        },
    },
    computed: {
        images(){
            let images = [];
            for(let index in this.theme.images){
                try{
                    images[index] = URL.createObjectURL(this.theme.images[index]);
                }
                catch(error){}
            }
            return images;
        }
    }
}
</script>